

import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom";
import Logo from "../Home/HomeComponents/GvLogoBlack.svg"
import GvVideo from "../../components/GvVideo"
import axios from "axios"
import DownloadRenderer from "./helpers/DownloadRenderer"
const GetDownload = (props) => {
    const history = useHistory();
    const [download, setDownload] = useState({ links: [] })
    const [loading, setLoading] = useState(false)

    const [downloadExpired, setDownloadExpired] = useState(false)

    useEffect(async () => {
        getDownloadObject()

    }, [])

    async function downloadFiles() {
        console.log("downloading")
        let links = download.links;
        if (!links.length) {
            return;
        }

        links.forEach(async (link) => {
            let res = await axios.get("/.netlify/functions/download-file?key=" + link)
            console.log(res.data.link)
            window.open(res.data.link, '_blank')
        })


        // https://stackoverflow.com/a/39387533

    }

    async function getDownloadObject() {
        try {
            setLoading(true)
            let res = await fetch("/.netlify/functions/get-download?id=" + props.match.params.downloadId).then(res => {
                return res.json()
            })

            let datevalid = isDownloadExpired(res.download.created)
            if (!datevalid) {
                setDownloadExpired(true)
            }
            setDownload(res.download)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }

    }

    let style = { backgroundColor: "#FFA3A3" }
    return (
        <>

            <div className="min-h-screen flex items-center bg-blue-700 justify-between w-full" style={style}>
                <div className="mx-4 lg:ml-20 ">
                    <img alt="govisually logo" src={Logo} />
                    {loading ? ShowLoading() : ShowContent(download, downloadFiles, downloadExpired)}
                </div>
                <div className="hidden md:block lg:w-1/2">
                    <GvVideo />
                </div>
            </div>
        </>

    )

}

function ShowContent(download, downloadFiles, expired) {
    return (
        <>
            <div>
                {expired ? <div>
                    <h1 className="text-4xl font-bold text-gray-800 text-left mt-6">
                        Oops! the files you are trying <br /> to access have expired
                    </h1>
                </div> : <div>
                    <h1 className="text-4xl font-bold text-gray-800 text-left mt-6">
                        {download.sender_name} has <br />  shared {download.links.length} {download.links.length===1?'file':'files'}
                    </h1>
                    {download.links.map((link)=>{
                        return(
                            <DownloadRenderer link={link}/>
                        )
                    })}
                    {/* <div className="w-4/5">
                        <button className="focus:outline-none bg-gray-900 px-6 py-2 mt-8 text-white rounded-md" onClick={downloadFiles}>Download Files</button>
                    </div> */}
                </div>}



            </div>

        </>
    )
}

function ShowLoading() {
    return (
        <div>
            <h1 className="text-4xl font-bold text-gray-800 text-left mt-20">
                Loading....
            </h1>
        </div>
    )
}

function isDownloadExpired(uploadDate) {
    let numberOfDaysValid = process.env.REACT_APP_DOWNLOAD_EXPIRY_DAYS | 3;
    console.log(numberOfDaysValid)
    /// https://stackoverflow.com/a/3224854
    const date1 = new Date(uploadDate);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    console.log(diffDays)
    return diffDays <= numberOfDaysValid
}


export default GetDownload