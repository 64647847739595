const Banner = (props) => {
    function goToGV() {

    }

    return (
        <>
            <div className="hidden lg:block w-1/2">
                <div className="h-screen bg-center bg-cover w-full" >
                    <div className="flex items-center justify-center text-white h-full text-center w-full">

                        <div>
                            <h1 className="text-4xl shadow p-2 bg-gray-900 rounded">We do more than just transfer files...</h1>
                            <button className="bg-red-400 float-right hover:bg-red-500 font-bold mt-10 px-5 py-2 rounded text-sm" onClick={goToGV}>Take a look</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Banner