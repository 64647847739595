import Home from "./pages/Home/HomeNew.js"
import GetDownload from "./pages/getDownload/GetDownload"
import ListDownloads from "./pages/ListDownloads/ListDownloads"
import FilesSentAcknowledgement from "./pages/FilesSentAcknowledgement/FilesSentAcknowledgement"
let router = {
    routes: [
        { path: '/', component: Home, exact: true },
        { path: '/download/:downloadId', component: GetDownload, exact: true },
        { path: '/downloads-gv-list', component: ListDownloads, exact: true },
        { path: '/files-sent', component: FilesSentAcknowledgement, exact: true },
    ]
}


export default router;