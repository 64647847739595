import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Tick from "./Tick.svg";
import Upload from "./Upload.svg";

import "animate.css"

const FileUploader = (props) => {
  const [percentage, setPercentage] = useState(0);
  const [done, setDone] = useState(false);
  useEffect(() => {
    // Update the document title using the browser API
    uploadFile();
  }, []);


  async function uploadFile() {
    let file = props.file;

    try {
      let name = file.name + uuidv4();
      
      let res = await axios.put("/.netlify/functions/sign-file", {
        file_name: name,
        file_type: file.type,
      });

      let signedUrl = res.data;

      await uploadS3(signedUrl, file, (progress)=>{
        console.log(progress)
         setPercentage(progress.toFixed(0))
      })

      setDone(true);

      props.pushToDownloadList(name);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }


  async function uploadS3(url, file, progressCallback) {
    return new Promise(function (resolve, reject) {
  
      const xhr = new XMLHttpRequest();
  
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr)
          }
          else {
            reject(xhr)
          }
        }
      };
  
      if (progressCallback) {
        xhr.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            var percentComplete = (e.loaded / file.size) * 100;
            progressCallback(percentComplete);
          }
        };
      }
  
      xhr.open("PUT", url);
      xhr.send(file);
    });
  }

  return (
    <div
      key={props.file.name}
      className="mb-2 py-2 relative cursor-pointer text-white"
    >
      <div className=" w-full h-full z-10 relative flex items-center justify-between">
        {done ? <img alt="tick" width="36" src={Tick} /> : <img className="animate__animated animate__swing animate__infinite	infinite" alt="tick" width="36" src={Upload} />}
        <div className="flex-auto text-xl overflow-hidden truncate pl-4">
        {percentage + "%"} {props.file.name}
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
