import React from "react";

// Step One
import LeftStepOne from "./Steps/Left/StepOne";
import RightStepOne from "./Steps/Right/StepOne";

// Step Two
import LeftStepTwo from "./Steps/Left/StepTwo";

import GvVideo from "../../components/GvVideo";
import UserDataCollectionView from "./HomeComponents/UserDataCollectionView";

const MainPageRender = (props) => {
  return (
    <div className="min-h-screen flex items-center justify-between w-full pb-10" style={getBgColor(props)}>

      {props.files.length ? (
        RenderView(props)
      ) : (
        <>
          <div className="w-full lg:w-1/2">
            <LeftStepOne fileInputRef={props.fileInputRef} showExceedError={props.showExceedError} />
          </div>


          <div
            onClick={props.onTargetClick}
            className="hidden w-1/2 lg:block mx-auto"
          >
            <RightStepOne />
            <input
              onChange={props.onFileInputChange}
              ref={props.fileInputRef}
              type="file"
              className="hidden"
              multiple
            />
          </div>

        </>
      )}
    </div>
  );
};

function RenderView(props) {
  return (
    <>
      {props.files.length !== props.finalizedKeys.length ? (
        <>
          <div className="flex w-full items-center ">
            <div className="w-full lg:w-1/2">
              <LeftStepTwo
                pushToDownloadList={props.pushToDownloadList}
                files={props.files}
              />
            </div>

            <div className="hidden lg:block lg:w-1/2">
              <GvVideo />
            </div>
          </div>
        </>
      ) : (
        <UserDataCollectionView
          pushToDownloadList={props.pushToDownloadList}
          files={props.files} finalizedKeys={props.finalizedKeys} />
      )}
    </>
  );
}


function getBgColor(props) {
  let style = {
    backgroundColor: "#0D6ABE",
    transition: "all 2s"
  };

  if (props.files.length) {
    if (props.files.length !== props.finalizedKeys.length) {
      style.backgroundColor = "#009A5D"
    } else if (props.files.length === props.finalizedKeys.length) {
      style.backgroundColor = "#FFA3A3"
    }
  }

  return style
}

export default MainPageRender;
