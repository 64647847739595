import React from "react"
import axios from "axios";

import Arrow from "../../Home/HomeComponents/Arrow.svg"

const GetDownload = (props) => {

    async function downloadFile(){
        try{
            console.log(props.link)
            console.log("going to download this file")
            

            let res = await axios.get("/.netlify/functions/download-file?key="+props.link)

            window.open(res.data.link, '_blank')

        }catch(err){
            console.log(err)
        }
    }

    return (
        <>
          <div
            className="mb-2 py-2 relative cursor-pointer text-white mt-5"
            >
            <div className=" w-full h-full z-10 relative flex items-center justify-between" onClick={downloadFile}>
                <img className="p-2 bg-gray-700 hover:bg-black transform rotate-180 rounded-full" alt="tick" width="36" src={Arrow}  /> 
                <div className="flex-auto text-xl overflow-hidden truncate pl-4 hover:text-black">
                 {props.link}
                </div>
            </div>
            </div>
        </>

    )

}

export default GetDownload