import React from "react"
import Arrow from "../pages/Home/HomeComponents/Arrow.svg"
const DropItLikeItsHot = (props) => {
    var style = {
        backgroundColor: "#FC4E46"
    };

    var stylebg = {
        backgroundColor: "#EE372F"
    }


    return (
        <div className="h-screen w-full absolute z-50" style={style}>
            <div className="items-center w-full justify-center flex items-center relative h-full text-center">
                <div>
                    <img src={Arrow} style={stylebg} className="block mx-auto mb-20 p-20 rounded-full" alt="arrow" />
                    <div className="text-white text-6xl font-bold">
                        Drop it like it's hot!
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DropItLikeItsHot