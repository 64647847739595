
const GvVideo = (props) => {

    return (
        <div>
            <iframe width="90%" height="480" src="https://www.youtube.com/embed/jRZ1_SLKBfM?autoplay=1&mute=1&showinf=0&controls=0&modestbranding=1&autohide=1&mode=transparent&playlist=jRZ1_SLKBfM&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    )
}

export default GvVideo