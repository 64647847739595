import React from "react";

import Logo from "../../HomeComponents/GvLogoWhite.svg";

const StepOneLeft = (props) => {
  const onTargetClick = () => {
    props.fileInputRef.current.click();
  };

  return (
    <div className="lg:ml-20 px-4 md:px-20">
      <img alt="govisually logo" src={Logo} />
      <h1 className="text-4xl lg:text-6xl font-bold text-white text-left mt-20 pr-10">
        Share large files <br />
        with anyone,
        <br />
        anywhere.
      </h1>
      <p className="mt-10 hidden md:block text-white">
        Drop files to begin,
        <span
          onClick={onTargetClick}
          className="text-yellow-400 cursor-pointer inline-block pl-2"
        >or select files</span>
      </p>

      <div className="my-4 text-white text-sm font-extralight">
        {props.showExceedError ?
          <span className="text-red-300">You have exceeded the upload limit of {getMaxUploadSizeString()} GB</span> : <span >Even good friends have some limits right? <br />
            At the moment that limit is {getMaxUploadSizeString()} GB</span>}
      </div>
      <p
        onClick={onTargetClick}
        className="mt-10 md:hidden text-white cursor-pointer text-sm lg:text-normal"
      >
        Tap here to get started
      </p>
    </div>
  );
};

export default StepOneLeft;


function getMaxUploadSizeString() {
  return process.env.REACT_APP_MAX_UPLOAD_SIZE
}