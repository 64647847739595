import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import routeConfig from "./routes"

function App() {
  return (
   <>
     <Router>
      <Switch>
        <Repeater />
      </Switch>
    </Router>
   </>
  );
}



function Repeater() {

  return (
    <div>
      {routeConfig.routes.map((item) => {
        return (
          <Route path={item.path} component={item.component} exact={item.exact} key={item.path} />
        )
      })}
    </div>
  )
}

export default App;
