import React, { useState } from "react";
import Logo from "../HomeComponents/GvLogoBlack.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";
import GvVideo from "../../../components/GvVideo";
const validator = require("validator");

const UserDataCollectionView = (props) => {
  const history = useHistory();

  const [sender, setSender] = useState("");
  const [sendee, setSendee] = useState("");
  const [senderName, setSenderName] = useState("");
  const [note, setNote] = useState("");

  const [sending, setSending] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  async function sendFiles() {
    setErrorMessage("");

    if (senderName.length < 2) {
      setErrorMessage("Please enter your name");
      return;
    }

    if (!validator.isEmail(sender)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }
    if (!validator.isEmail(sendee)) {
      setErrorMessage("Please enter a valid sendee email address");
      return;
    }

    if (sending) {
      return;
    }
    try {
      setSending(true);
      await axios.post("/.netlify/functions/create-download", {
        links: props.finalizedKeys,
        sendee_emails: [sendee],
        sender_email: sender,
        sender_name: senderName,
        note: note,
      });
      setSending(false);
      history.push("/files-sent");
    } catch (err) {
      setSending(false);
    }
  }

  const onSenderEmailChange = (e) => {
    setSender(e.target.value);
    setErrorMessage("");
  };
  const onSendeeEmailChange = (e) => {
    setSendee(e.target.value);
    setErrorMessage("");
  };
  const onSenderNameChange = (e) => {
    setSenderName(e.target.value);
    setErrorMessage("");
  };
  const onNoteChange = (e) => {
    setNote(e.target.value);
  };
  return (
    <>
      <div className="flex w-full items-center">
        <div className="text-left text-gray-500 text-xs w-full lg:w-1/2">
          <div className=" mx-4 lg:ml-20">
            <img alt="govisually logo" src={Logo} />
            <h1 className="text-xl lg:text-3xl font-bold text-gray-900 text-left mt-10">
              Howdy stranger! <br />
              Who do you wanna share with?
            </h1>
            <div className="mt-10 w-full lg:w-3/4">
              <input
                type="text"
                value={senderName}
                onChange={onSenderNameChange}
                className="border-b-2 border-gray-900 bg-transparent w-full lg:w-4/5 py-2 text-black focus:outline-none placeholder-gray-900 text-gray-700 text-lg"
                placeholder="Your name"
              ></input>

              <input
                type="text"
                value={sender}
                onChange={onSenderEmailChange}
                className="border-b-2 border-gray-900 bg-transparent w-full lg:w-4/5 py-2  text-black focus:outline-none mt-4 placeholder-gray-900 text-gray-700 text-lg"
                placeholder="Your email"
              ></input>

              <input
                type="text"
                value={sendee}
                onChange={onSendeeEmailChange}
                className="border-b-2 border-gray-900 bg-transparent w-full lg:w-4/5 py-2 text-black focus:outline-none mt-10 placeholder-gray-900 text-gray-700 text-lg"
                placeholder="Recipient email"
              ></input>

              <input
                type="text"
                value={note}
                onChange={onNoteChange}
                className="border-b-2 border-gray-900 bg-transparent w-full lg:w-4/5 py-2 text-black focus:outline-none mt-4 placeholder-gray-900 text-gray-700 text-lg"
                placeholder="Message (optional)"
              ></input>
              <div className="text-red-400 text-sm pt-4">{errorMsg}</div>
              <div>
                <button
                  className="focus:outline-none bg-black px-8 py-2 mt-8 rounded-md pt-3 text-white text-lg"
                  onClick={sendFiles}
                >
                  {sending ? "Hold tight, sending files ..." : "Alright, let's do this"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 hidden lg:block">
          <GvVideo />
        </div>
      </div>
    </>
  );
};

export default UserDataCollectionView;
