import React from "react";

import Logo from "../../HomeComponents/GvLogoWhite.svg";
import FileRenderer from "../../HomeComponents/FileRenderer";
const StepOneLeft = (props) => {
  return (
    <div className="px-5 lg:ml-20">
      <img alt="govisually logo" src={Logo} />
      <h1 className="text-2xl lg:text-5xl font-bold text-white text-left mt-10">
        Uploading {props.files.length} {props.files.length > 1 ? 'files' : 'file'}
      </h1>
      <div className="mt-4">
        <FileRenderer
          pushToDownloadList={props.pushToDownloadList}
          files={props.files}
        />
      </div>
    </div>
  );
};

export default StepOneLeft;
