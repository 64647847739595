import React from "react";
import Logo from "../Home/HomeComponents/GvLogoWhite.svg"
import GvVideo from "../../components/GvVideo";
import { useHistory } from "react-router-dom";
const FilesSentAcknowledgement = (props) => {
    const history = useHistory();
    let style = { backgroundColor: "#FC4E46" }

    function goToHome() {
        history.push("/");
    }
    return (
        <>
            <div className="min-h-screen flex items-center justify-between w-full" style={style}>
                <div className="lg:ml-20 mx-10 w-full lg:w-1/2">
                    <img alt="govisually logo" src={Logo} />
                    <h1 className="text-xl lg:text-4xl font-medium text-white  text-left mt-10 font-bold">
                        High five! Your files are sent
                    </h1>
                    <h2 className="text-md text-white font-light text-left mt-4">{getExpiryDate()}</h2>
                    <p className="w-full w-1/2 mt-5 text-white" >
                        Sharing files for client review? <br /> Try GoVisually, the simplest and easiest way to get  <br />  fast and accurate feedback directly on creative files.
                    </p>
                    <div className="w-full lg:w-4/5">
                        <a href="https://govisually.com/?utm_source=gvshare&utm_medium=button&utm_campaign=gvshare" target="_blank"
                            className="focus:outline-none inline-block rounded-md
                         bg-black px-6 py-2 mt-8 text-white" rel="noreferrer" >Learn more about GoVisually</a>


                        <button className="border rounded-md ml-2 border-white px-6 py-2 mt-8 text-white cursor-pointer" onClick={goToHome}>Share more files</button>
                    </div>

                </div>
                <div className="hidden lg:block w-1/2">
                    <GvVideo />
                </div>
            </div>
        </>
    )
}

function getExpiryDate() {
    let numberOfDays = process.env.REACT_APP_DOWNLOAD_EXPIRY_DAYS | 3;
    var date = new Date(Date.now() + numberOfDays * 24 * 60 * 60 * 1000);


    const month = date.toLocaleString('default', { month: 'long' });

    return `Files expire on ${date.getDate()} ${month} ${date.getFullYear()}`

}
export default FilesSentAcknowledgement