import React from "react";

import Arrow from "../../HomeComponents/Arrow.svg";

const StepOneRight = (props) => {
  let bgStyle = { backgroundColor: "#0C5FAB" }
  return (
    <div className="mx-auto">
      <img
        src={Arrow}
        width="400"
        style={bgStyle}
        className="p-20 mx-auto rounded-full cursor-pointer"
        alt="arrow"
      />
    </div>
  );
};

export default StepOneRight;
