import React, { useState, useRef } from "react";
import DropItLikeItsHot from "../../components/DropItLikeItsHot";
import { FileDrop } from "react-file-drop";
import MainPageRender from "./MainPageRender";
const HomeMain = (props) => {
  const fileInputRef = useRef(null);
  const [dropping, setDropping] = useState(false);
  const [files, setFiles] = useState([]);
  const [showExceedError, setExceedError] = useState(false)

  const [finalizedKeys, setFinalizedKeys] = useState([]);

  function isDropping() {
    setDropping(true);
  }
  function isNotDropping() {
    setDropping(false);
  }

  const onFileInputChange = (event) => {
    var files = [...event.target.files].map((file) => {
      return file;
    });
    setFiles(files);
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  function fileDropped(fileObjects, event) {
    setExceedError(false)
    let MAX_LIMIT_EXCEEDED = false;
    var files = [...fileObjects].map((file) => {

      let sizeInGB = file.size / (1024 * 1024 * 1024)

      if (sizeInGB > process.env.REACT_APP_MAX_UPLOAD_SIZE) {
        MAX_LIMIT_EXCEEDED = true;
      }
      return file;
    });
    if (MAX_LIMIT_EXCEEDED) {
      setDropping(false);
      setExceedError(true)
      return;
    }
    setFiles(files);
    setDropping(false);
  }

  const pushToDownloadList = (item) => {
    setFinalizedKeys((keys) => [...keys, item]);
  };

  return (
    <>
      <div className="min-h-screen">
        <FileDrop
          onFrameDragEnter={isDropping}
          onFrameDragLeave={isNotDropping}
          onDrop={fileDropped}
        >
          {dropping ? <DropItLikeItsHot /> : ""}
          <MainPageRender files={files} showExceedError={showExceedError} pushToDownloadList={pushToDownloadList} finalizedKeys={finalizedKeys}
            onFileInputChange={onFileInputChange}
            onTargetClick={onTargetClick}
            fileInputRef={fileInputRef}
          />
        </FileDrop>
        {files.length ? '' : <Footer />}
      </div>


    </>
  );
};

function Footer() {
  let style = {
    backgroundColor: "#0D6ABE"
  };

  return (
    <div className="px-8 py-5 font-light text-white text-xs" style={style}>
      <ul className="flex justify-end">

        <li className="px-4">Powered by <a href="https://govisually.com/?utm_source=gvshare&utm_medium=footer&utm_campaign=gvshare">©GoVisually™</a></li>|
        <li className="px-4"> <a href="https://govisually.com/privacy/">Privacy</a> </li>|
        <li className="px-4"><a href="https://govisually.com/terms/">Terms</a></li>
      </ul>
    </div>
  )
}


export default HomeMain;
