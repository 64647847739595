

import FileUploader from "./FileUploader"
const FileRenderer = (props) => {


    return (
        <>
            <div className="text-left text-gray-500 text-xs">
                {props.files.map(file => {
                    return <FileUploader pushToDownloadList={props.pushToDownloadList} key={file.name} file={file} />
                })}
            </div>
        </>
    )
}

export default FileRenderer