import React, {useEffect, useState} from "react"
import Banner from "../Home/HomeComponents/Banner"
import { useHistory } from "react-router-dom";

const GetDownload = (props) => {
    const history = useHistory();
    const [list,setList] = useState([])
    useEffect(async()=>{
        let res = await fetch("/.netlify/functions/list-downloads").then(res => {
            return res.json()
        })
        console.log(res.downloads)
        setList(res.downloads)
        
    },[])

    function goToDownload(item){
        console.log(item)
       
        history.push("/download/"+item._id);
    }

    return (
        <>
            <div className="min-h-screen ">
                  
                    <div className="flex items-center bg-gradient-to-r from-pink-200 to-red-500">
                        <div className="w-full lg:w-1/2 h-screen flex items-center">
                            <div className="w-full">
                                <h1 className="text-4xl text-center font-bold text-white"><img className="inline-block" src="https://app.govisually.com/assets/images/gv_logo.svg" /></h1>

                                <div className="relative shadow-2xl bg-white w-4/5 xl:w-1/2 mx-auto mt-10 p-10 text-center rounded-lg h-96">

                                    <ul>
                                        {list.map((item)=>{
                                            return <li 
                                            onClick={()=>{goToDownload(item)}} 
                                            key={item._id} ></li>
                                        })}
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <Banner />
                    </div>
                </div>
        </>

    )

}

export default GetDownload